/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { PageSEO, useSEO } from '../components/SEO';
import Main from '../page-sections/_misc/TermsOfUse';

const TermsOfUse = ({ location }) => {
  //
  const { pathname } = location;
  const seo = useSEO(pathname);

  return (
    <article>
      <PageSEO title={seo.title} description={seo.description} pathname={pathname} featureImage="og-terms-of-use.jpg" />
      <Main />
    </article>
  );
};

export default TermsOfUse;

TermsOfUse.propTypes = {
  location: PropTypes.object.isRequired,
};
